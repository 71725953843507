export const updateStatus = async (usuario_N) => {
    try {
        const response = await fetch('https://sosseguros.vulcanics.mx/api/update-status', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "usuario_N": usuario_N
            })
        });
        if (response.ok) {
            return true;
        }
        else if (response.status === 404) {
            console.log("No se encontró información o ya se encuentra cerrado"+ JSON.stringify(response))
        } else {
            console.log("Algo salió mal")
        }
    } catch (error) {
        console.log("Algo salió mal al consultar los ejecutivos: " + error);
        return false
    }
};
