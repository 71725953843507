import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { BsPinFill } from 'react-icons/bs'; 
import io from 'socket.io-client'; 
import { useAuth } from '../../../context/AuthContext';
import { fetchAndSetClients } from '../../../lib/util';
const ClientsList = ({ setSelectedChat, isChatClosed }) => {
  const [selectedClient, setSelectedClient] = useState(null); 
  const [hoveredClient, setHoveredClient] = useState(null);
  const [clients, setClients] = useState([]);
  const socket = io('https://sosseguros.vulcanics.mx'); 

  const { user } = useAuth(); 

  useEffect(() => {
    const getChats = async () => {
      fetchAndSetClients(setClients);
    };
    getChats();
  
    // Configuramos el socket para recibir actualizaciones en tiempo real.
    socket.on('connect', () => {
      console.log('Conectado al servidor de WebSocket');
      socket.emit('get-chats');
    });
  
    socket.on('chats', (data) => {
      const assignedConversations = data.filter(client => client.responsable === String(user.id));
  
      if (assignedConversations.length > 0) {
        setClients((prevClients) => {
          // Creamos un nuevo array para los clientes actualizados.
          const updatedClients = [...prevClients];
  
          // Iteramos sobre las nuevas conversaciones asignadas.
          assignedConversations.forEach(newClient => {
            const existingClientIndex = updatedClients.findIndex(client => client.usuario_N === newClient.usuario_N);
  
            if (existingClientIndex !== -1) {
              // Si ya existe, actualizamos el cliente en el mismo índice.
              updatedClients[existingClientIndex] = newClient;
            } else {
              // Si no existe, lo añadimos al final de la lista.
              updatedClients.push(newClient);
            }
          });
  
          // Eliminar clientes que ya no están en la data nueva.
          return updatedClients.filter(client =>
            assignedConversations.some(newClient => newClient.usuario_N === client.usuario_N)
          );
        });
      }
    });
  }, [user.id]); // El efecto depende del user.id

  const sortedClients = Array.isArray(clients)
    ? clients.sort((a, b) => (a.status === 'Normal' ? 1 : -1))
    : [];

  const selectionHandler = (client) => {
    setSelectedChat(client);
    setSelectedClient(client.usuario_N); 
  };

  const handleMouseEnter = (client) => {
    setHoveredClient(client.usuario_N);
  };

  const handleMouseLeave = () => { 
    setHoveredClient(null);
  };

  return (
    <div>
      <h5 style={{ marginBottom: '16px' }}>Conversaciones</h5>
      <ListGroup variant="flush">
        {sortedClients.map((client) => (
          <ListGroup.Item
            key={client.usuario_N}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              backgroundColor:
                selectedClient === client.usuario_N || hoveredClient === client.usuario_N
                  ? '#F8F9FA'
                  : client.status !== 'Normal'
                  ? '#ffebee'
                  : '#ffffff',
              padding: '10px',
              cursor: 'pointer',
              borderLeft: isChatClosed(client.usuario_N) ? '5px solid #f5c6cb' : 'none', // Estilo de casos cerrados
            }}
            onClick={() => selectionHandler(client)}
            onMouseEnter={() => handleMouseEnter(client)}
            onMouseLeave={handleMouseLeave}
          >
            {client.status !== 'Normal' && (
              <BsPinFill
                size={20}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: 'red',
                }}
              />
            )}

            <div style={{ flexGrow: 1 }}>
              <h6>{client.persona_que_reporta}</h6>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default ClientsList;
