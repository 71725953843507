export const sendWhatsApp = async (userPhone, phone_number_id, message, origin) => {
    try {
        const response = await fetch("https://sosseguros.vulcanics.mx/api/send-message", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userPhone": userPhone,
                "phone_number_id": phone_number_id,
                "message": message,
                "origin": origin
            })
        });

        if (response.ok) {
            const data = await response.json();  
            console.log("El mensaje se ha enviado", data);  
            return true;
        } else {
            const errorData = await response.json();  
            console.log("No se pudo mandar el mensaje", errorData); 
            return false;
        }
    } catch (error) {
        console.log("Error al momento de comunicarse con el servidor: " + error);
        return false;  
    }
};
