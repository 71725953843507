import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AssignConversationModal = ({
  show,
  handleClose,
  handleAssignConversation,
  selectedRepresentative,
  clients,
  selectedClient,
  setSelectedClient
}) => {
  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Asignar conversación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedRepresentative && (
          <>
            <p>¿Asignar la conversación a {selectedRepresentative.username}?</p>
            <Form.Group>
              <Form.Label>Seleccionar cliente</Form.Label>
              <Form.Control as="select" value={selectedClient} onChange={handleClientChange}>
                <option value="">Seleccione un cliente</option>
                {clients.map((client, index) => (
                  <option key={index} value={client.usuario_N}>
                    {client.persona_que_reporta}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleAssignConversation}>
          Asignar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignConversationModal;
