export const getChatLogs = async (userPhone) => {
    try {
        const response = await fetch(`https://sosseguros.vulcanics.mx/api/chatlogs?userPhone=${userPhone}`, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        if (response.ok) {
            const responseJson = await response.json(); 
            const data = await responseJson;
            return data;
        } 
    } catch (error) {
        console.log("Algo salió mal al consultar el registro de chats: " + error);
    }
};
