// App.js
import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import ChatList from './components/ChatList';
import ChatView from './components/ChatView';
import LoginForm from './components/LoginForm'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { fetchAndSetClients, fetchAndSetAssignedConversations } from './lib/util';
import { AuthProvider, useAuth } from './context/AuthContext';

function AppContent() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [clients, setClients] = useState([]);
  const [closedChats, setClosedChats] = useState([]); 
  const [view, setView] = useState('chats');
  const [loading, setLoading] = useState(false); // Estado para manejar el loader

  const { user, logout } = useAuth(); 
  
  useEffect(() => {
    
  }, [user]);

  const handleChatClose = (chatId) => {
    setClosedChats([...closedChats, chatId]);  
  };

  const handleLogout = () => {
    setSelectedChat(null); 
    setView('chats'); 
    setClients([]); 
    setClosedChats([]); 
    logout();
  };

  if (!user) {
    return <LoginForm />;
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex" style={{ height: '100vh' }}>
      <Sidebar onViewChange={setView} onLogout={handleLogout} />
      <div className="d-flex flex-grow-1">
        <div className="chat-list bg-light p-3" style={{ width: '300px' }}>
          <ChatList
            setSelectedChat={setSelectedChat}
            clients={clients}
            closedChats={closedChats} 
          />
        </div>
        <div className="flex-grow-1 chat-view bg-white p-3">
          {selectedChat ? (
            <ChatView chat={selectedChat} onCloseCase={handleChatClose} />
          ) : (
            <div className="text-center text-muted">
              {view === 'chats' ? 'Selecciona un chat para ver la conversación' : 'Selecciona una notificación'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <AuthProvider> 
      <AppContent /> 
    </AuthProvider>
  );
}

export default App;
