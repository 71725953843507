import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';  
import '../styles/ChatView.css';
import { BsArrowRightCircle, BsList, BsWhatsapp } from 'react-icons/bs';
import { fetchAndSetChatlogs, fetchAndSetStatus, getStatusIncidencia } from '../lib/util';
import { sendWhatsApp } from '../api/sendWhatsApp';
import { toggleConversation } from '../api/toggleConversation';
import { io } from 'socket.io-client';

const ChatView = ({ chat, onCloseCase }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [prevMessages, setPrevMessages] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const [status, setStatus] = useState([]);
  const [isClose, setIsClose] = useState();
  const socketRef = useRef(null);  // Usar ref para la instancia de socket

  const isClient = chat.hasOwnProperty('userPhone');
  const contactName = isClient ? chat.persona_que_reporta : chat.username;
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (isClient) {
      const fetchAndSet = async () => {
        await fetchAndSetChatlogs(chat.userPhone, setPrevMessages);
        await getStatusIncidencia(chat.usuario_N, setStatus);
      };
      fetchAndSet();
      
      // Instanciar solo una vez el socket
      if (!socketRef.current) {
        socketRef.current = io('https://sosseguros.vulcanics.mx');
      }

      const socket = socketRef.current;

      // Suscripción al socket
      socket.emit('join-chatlog', { phoneNumber: chat.userPhone });
      console.log(`Subscribed to chatlog with phone number: ${chat.userPhone}`);
  
      // Escuchar los mensajes históricos del chat
      socket.on('chatlogs', (data) => {
        const formattedMessages = data.map(log => ({
          sender: log.origin === 'user' ? contactName : 'Bot',
          text: log.message,
          timestamp: log.timestamp,
        }));
        setPrevMessages(formattedMessages);
      });
  
      // Escuchar actualizaciones en tiempo real (nuevos mensajes)
      socket.on('chatlog-update', (data) => {
        const newMessage = {
          sender: data.origin === 'user' ? contactName : 'Bot',
          text: data.message,
          timestamp: new Date().toISOString(),
        };
        setMessages(prev => [...prev, newMessage]); // Agregar nuevo mensaje
      });
  
    }
  }, [chat, contactName, isClient]);  // Remover dependencias innecesarias como isClose y status

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, prevMessages]);

  const setClose = async () => {
    await fetchAndSetStatus(chat.usuario_N, setIsClose);
    setStatus('Cerrado');
  };

  const allMessages = [
    ...(isClient ? prevMessages.map((msg) => ({
      sender: msg.origin === 'user' ? contactName : 'Bot',
      text: msg.message,
      timestamp: msg.timestamp,
    })) : []),
    ...messages.map((msg) => ({
      sender: msg.sender,
      text: msg.text,
      timestamp: new Date().toISOString(),
    })),
  ];

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    const newMessages = [...messages, { sender: 'Tú', text: newMessage }];
    setMessages(newMessages);
    setNewMessage('');
  
    localStorage.setItem(`messages_${contactName}`, JSON.stringify(newMessages));
  
    if (socketRef.current) {
      socketRef.current.emit('send-message', { 
        userPhone: chat.userPhone, 
        message: newMessage, 
        origin: 'frontend' 
      });
    }
  
    if (isClient) {
      await handleWhatsappMessage(newMessage);
    }
  
    try {
      await toggleConversation(chat.userPhone);
      console.log(`Conversación alternada para el número: ${chat.userPhone}`);
    } catch (error) {
      console.error("Error al alternar la conversación:", error);
    }
  };
  

  const handleWhatsappMessage = async (message) => {
    if (isClient) {
      try {
        const sendMessage = await sendWhatsApp(chat.userPhone, chat.phone_number_id, message, "frontend");
        console.log("Mensaje enviado a WhatsApp:", sendMessage);
      } catch (error) {
        console.error("Error al enviar el mensaje a WhatsApp:", error);
      }
    }
  };

  const handleCaseClose = () => {
    Swal.fire({
      title: '¿Deseas cerrar el caso?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Caso cerrado', '', 'success');
        setMessages([...messages, { sender: 'Sistema', text: 'El caso ha sido cerrado' }]);
        setClose();
        onCloseCase(chat.id); // Notificar que el chat fue cerrado
      }
    });
  };

  return (
    <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
      <div className="chat-header">
        <div className="contact-info">
          <h4>{contactName}</h4>
          <h5>{chat.userPhone}</h5>
        </div>

        {isClient && (
          <>
            <h5>{isClient ? ` Estatus: ${status}` : ""}</h5>

            <div className="icon-contents">
              <div className="chat-icons" onClick={() => setOpenChat(!openChat)}>
                <h4 className="chatConver">Tomar conversación</h4>
                <BsWhatsapp size={24} />
              </div>

              <div className="chat-icons" onClick={handleCaseClose}>
                <BsList size={24} />
              </div>
            </div>
          </>
        )}
      </div>

      {openChat && messages.length === 0 && (
        <div className="chat-start-message">
          <p> Nuevo chat iniciado</p>
        </div>
      )}

      <div className="chat-content">
        {allMessages.map((msg, index) => (
          <div key={index} className={msg.sender === 'Tú' ? 'message-container-right' : 'message-container'}>
            <div className={`message ${msg.sender === 'Tú' ? 'message-right' : 'message-left'}`}>
              <p>
                <strong>{msg.sender}:</strong>
                <span className='mx-3'>{new Date(msg.timestamp).toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })} - {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
              </p>
              <p>{msg.text}</p>
            </div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      {openChat && isClient && (
        <div className="message-input-container">
          <div className="message-input d-flex align-items-center">
            <input
              type="text"
              className="form-control"
              placeholder="Escribe un mensaje..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <div className="buttonSend" onClick={handleSendMessage}>
              <BsArrowRightCircle size={28} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatView;
