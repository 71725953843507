import React, { useEffect, useState } from 'react';
import '../styles/ChatList.css';
import RepresentativeList from './_partials/ChatList/RepresentativeList';
import ClientsList from './_partials/ChatList/ClientList';
import { useAuth } from '../context/AuthContext';

const ChatList = ({ setSelectedChat, closedChats }) => {
  const { user } = useAuth(); 

  const isChatClosed = (chatId) => {
    return closedChats.includes(chatId);
  } 

  return (
    <div className="main-container">
      <div className="chat-list">
        <h5 className='mt-2'>Santander S.O.S seguros</h5>
        <input type="text" className="form-control my-3" placeholder="Buscar..." />
        
        <ClientsList setSelectedChat={setSelectedChat} isChatClosed={isChatClosed} />
        {user.admin === "yes" && <RepresentativeList   /> }
      </div>
      
      <div className="chat-content">
        {/* Aquí iría el contenido del chat seleccionado */}
      </div>
    </div>
  );
};

export default ChatList;
