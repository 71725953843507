import React, { useState, useEffect } from 'react';
import { ListGroup, Dropdown, Button } from 'react-bootstrap';
import { fetchAndSetClients, fetchAndSetRepresentatives } from '../../../lib/util';
import { asignConversation } from '../../../api/asignConversation';
import AssignConversationModal from '../../modals/AssignConversationModal';
import CreateUserModal from '../../modals/CreateUserModal';
import EditUserModal from '../../modals/EditUserModal';

const RepresentativeList = () => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [representatives, setRepresentatives] = useState();
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect (() => {
    fetch();
  }, [])

  const fetch = async () => {
    await fetchAndSetRepresentatives(setRepresentatives);
    await fetchAndSetClients(setClients);

  };

  const fetchToggler = () => {
    setShouldFetch(false);
  };

  if (shouldFetch) {
    fetch();
    fetchToggler();
  };

  const handleShowAssignModal = (representative) => {
    setSelectedRepresentative(representative);
    setShouldFetch(true);
    setShowAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false);
    setSelectedRepresentative(null);
    setSelectedClient('');
  };

  const handleAssignConversation = () => {
    asignConversation(selectedClient, selectedRepresentative.id);
    setShouldFetch(true)
    handleCloseAssignModal();
  };

  const handleShowEditUserModal = (representative) => {
    setSelectedRepresentative(representative);
    setShowEditUserModal(true);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h5>Ejecutivos</h5>
        <Button 
          className="rounded-circle mb-2 border-white text-dark d-flex justify-content-center align-items-center button-hover"
          style={{ width: '10px', height: '30px', borderWidth: '3px', backgroundColor: 'transparent' }}
          onClick={() => setShowCreateModal(true)}
        >
          +
        </Button>
      </div>
      <ListGroup variant="flush">
        {representatives && representatives.length > 0 && representatives.map((representative) => (
          <ListGroup.Item key={representative.id} className="d-flex align-items-center closed-chat" action>
            <div className="flex-grow-1">
              <h6>{representative.username}</h6>
            </div>
            <Dropdown>
              <Dropdown.Toggle as="div" className="btn p-0">
                <i className="fas fa-ellipsis-v"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleShowAssignModal(representative)}>
                  Asignar conversación
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleShowEditUserModal(representative)}>
                  Actualizar usuario
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <AssignConversationModal 
        show={showAssignModal} 
        handleClose={handleCloseAssignModal} 
        handleAssignConversation={handleAssignConversation} 
        selectedRepresentative={selectedRepresentative} 
        clients={clients} 
        selectedClient={selectedClient} 
        setSelectedClient={setSelectedClient} 
      />
      <CreateUserModal show={showCreateModal} closeCreateUserModal={() => setShowCreateModal(false)} setShouldFetch={setShouldFetch} />
      <EditUserModal show={showEditUserModal} closeEditUserModal={() => setShowEditUserModal(false)} selectedRepresentative={selectedRepresentative} setShouldFetch = {setShouldFetch} />
    </div>
  );
};

export default RepresentativeList;
