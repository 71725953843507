import { getClients } from "../api/clients";
import { getRepresentatives } from "../api/representatives";
import { getChatLogs } from "../api/chatlogs";
import { updateStatus } from "../api/caseStatus";
import { userAuth } from "../api/userAuth";
import { generateReport } from "../api/generateReport";

export const fetchAndSetRepresentatives = async(setter) => {
    const reps = await getRepresentatives();
    setter(reps);
}
export const fetchAndSetClients = async (setter) => {
    const clients = await getClients();
    setter(clients);
}
export const fetchAndSetChatlogs = async (userPhone, setter) => {
    const chatlogs = await getChatLogs(userPhone);
    setter(chatlogs);
}
export const getStatusIncidencia = async (usuario_N, setter) => {
    try {
        const clientes = await getClients();

        const cliente = clientes.find(cliente => cliente.usuario_N === usuario_N);
        
        if (cliente) {
            const status =  cliente.estatus_de_incidencia;
            setter(status);
            return cliente.estatus_de_incidencia
        } else {
            return `No se encontró cliente con usuario_N: ${usuario_N}`;
        }
    } catch (error) {
        console.log("Error al obtener el estatus de la incidencia:", error);
        return "Error al obtener el estatus de la incidencia";
    }
};
export const fetchAndSetStatus = async (usuario_N, setter) => {
    try {
        const changeStatus = await updateStatus(usuario_N);
        if(changeStatus) {
            setter(changeStatus);
            return changeStatus;
        }
    } catch (error) {
        console.log("Error al cambiar el estatus de la incidencia:", error);
    }
};
export const fetchAndSetHumanRequests = async (setter) => {
    try {
        const clients = await getClients ();
        if (clients) {
            const needsSupport = clients.filter((client) => client["status"] !== "Normal" && client["estatus_de_incidencia"] !== "cerrado" );
            setter(needsSupport);
            return needsSupport;
        }
    } catch (error) {
        console.log("Error al obtener las conversaciones escaladad", error)
    }
}
export const fetchAndSetLogin = async (username, password, login, setError) => {
    try {
        const fetchedUser = await userAuth(username, password);
        if (fetchedUser) {
            login(fetchedUser.user)
        } else {
            setError("Credenciales Incorrectas, por favor, intente de nuevo.")
        }
    } catch (error) {
        setError("Error al intentar establecer sesión", error)
    }
}
export const fetchAndSetAssignedConversations = async (representativeId, setter) => {
    try {
        const clients = await getClients();
        if(clients) {
            const assignedConversations = clients.filter((client) => client["responsable"] === String(representativeId));
            setter(assignedConversations);
            return assignedConversations;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Error al intentar consultar las conversaciones asignadas");
    }
}
export const fetchAndDownloadPeriod = async (startDate, endDate) => {
    try {
        const response = await generateReport(startDate, endDate);

        if (response.ok) {  // Verifica si la respuesta de la API es exitosa
            const data = await response.text();  // Convierte la respuesta a texto (CSV)
            
            // Crear un Blob con los datos CSV
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace temporal para descargar
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Reporte_${startDate}_to_${endDate}.csv`);

            // Simular clic en el enlace para descargar el archivo
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace y liberar memoria
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else {
            console.log("Sin información para descargar");
            return false;
        }
    } catch (error) {
        console.log("Hubo un error al intentar descargar el periodo: ", error);
        return false;
    }
}
