export const getRepresentatives = async () => {
    try {
        const response = await fetch('https://sosseguros.vulcanics.mx/api/get-users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        
        // Comprobamos si la respuesta fue exitosa
        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        // Parseamos la respuesta a JSON y la devolvemos
        const data = await response.json();
        return data;

    } catch (error) {
        console.log("Algo salió mal al consultar los ejecutivos: " + error);
        return null;  // Devolvemos null en caso de error
    }
};
