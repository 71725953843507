import React, {useState} from 'react';
import { BsFillPersonFill, BsBoxArrowRight, BsFillCalendar3RangeFill} from 'react-icons/bs';
import SetPeriodModal from './modals/SetPeriodModal';
import '../styles/Sidebar.css';
import { useAuth } from '../context/AuthContext';
const Sidebar = ({ onViewChange, onLogout  }) => {
  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const { user } = useAuth(); 


  const handleShowPeriodModal = () => {
    setShowPeriodModal(true);
  }


  return (
    <>
    <div className="sidebar">
      <div className="sidebar-icon" title="Perfil">
        <BsFillPersonFill size={24} />
      </div>
      {user.admin ==="yes" && (
      <div className="sidebar-icon" title="Seleccionar Fechas" >
        <BsFillCalendar3RangeFill size={24} onClick={handleShowPeriodModal} />
      </div>
      )}
      {/* <div className="sidebar-icon" title="Chats" onClick={() => onViewChange('chats')}>
        <BsChat size={24} />
      </div>
      <div className="sidebar-icon" title="Notificaciones" onClick={() => onViewChange('notifications')}>
        <BsBell size={24} />
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div className="sidebar-iconBot" title="Configuración">
        <BsGear size={24} />
      </div> */}
      <div className="sidebar-iconBot" title="Cerrar sesión">
        <BsBoxArrowRight size={24} onClick={onLogout}  />
      </div>
    </div>

    <SetPeriodModal show={showPeriodModal} setShowPeriodModal={setShowPeriodModal} />
    </>
    
  );
};

export default Sidebar;
