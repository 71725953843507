import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { fetchAndSetLogin } from '../lib/util';
import '../styles/LoginForm.css';  
import santanderLogo from '../img/santander.png';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { login } = useAuth(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchAndSetLogin(username, password, login, setError);
  };

  return (
    <div className="login-page">
      <div className="login-form-section">
        <div className="login-container">
            <div className='login-image'>
              <img src={santanderLogo} alt="Santander logo" className="login-santander-logo" />
            </div>
            <h2 className="login-title">Ingreso</h2>
          {error && <div className="login-alert login-alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="login-form-group">
              <label htmlFor="username" className="login-label">Usuario</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-form-control"
                placeholder="Ingresa tu usuario"
                required
              />
            </div>
            <div className="login-form-group">
              <label htmlFor="password" className="login-label">Contraseña</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-form-control"
                placeholder="Ingresa tu contraseña"
                required
              />
            </div>
            <button type="submit" className="login-btn-primary">
              Iniciar sesión
            </button>
          </form>
        </div>
      </div>
      <div className="login-info-section">
        <div className="login-info-content">
          <h1>SOS Seguros</h1>
          <p>Canal de atención para ejecutivos RED de Banco Santander. 
            Aquí podrás responder, consultas y brindar asistencia a clientes en la emisión, documentación y servicios postventa de seguros.</p>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
