export const userAuth = async (username, password) => {
    try {
        const response = await fetch ("https://sosseguros.vulcanics.mx/api/auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username" : username,
                "password": password
            })
        });
        const data = await response.json();
        if (data.message === 'Login successful.') {
            return data; 
        } else {
            return false;
      }
    
    } catch (error) {
        console.log("Algo salió mal al intentar llamar a la API de auth", error)    
    }
};