import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { createUser } from '../../api/createUser';

const CreateUserModal = ({ show, closeCreateUserModal, setShouldFetch }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!username || !password) {
      setError('El nombre de usuario y la contraseña son obligatorios.');
      return;
    }
    createUser(username, password)
      .then((result) => {
        if (result) {
          setShouldFetch(true);
          handleClose();
        } else {
          setError('Error al crear el usuario.');
        }
      })
      .catch((error) => {
        setError('Ocurrió un error al intentar crear el usuario.');
      });
  };
  const handleClose = () => {
    setError("");
    setPassword("");
    setUsername("");
    closeCreateUserModal()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Añadir nuevo ejecutivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Ingrese el nombre de usuario"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='mt-2'>Contraseña</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese la contraseña"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Crear
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserModal;
