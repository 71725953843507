import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { FaCalendarAlt } from 'react-icons/fa'; 
import '../../styles/SetPeriodModal.css';
import { useAuth } from '../../context/AuthContext';
import { fetchAndDownloadPeriod } from '../../lib/util';
const SetPeriodModal = ({ show, setShowPeriodModal }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { user } = useAuth(); 
  const today = new Date();

  const handleSaveValidation = () => {
    if (!startDate || !endDate) {
      alert('Por favor selecciona ambas fechas.');
      return;
    }
    if (startDate > today || endDate > today) {
      alert('Las fechas deben ser anteriores a la fecha actual.');
      return;
    }
    if (startDate > endDate) {
      alert('La fecha de inicio no puede ser posterior a la fecha de conclusión.');
      return;
    }
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    handleSave(formattedStartDate, formattedEndDate);
  };
  const handleSave = (startDate,endDate) => {
    if(user.admin === "yes") {
      const response = fetchAndDownloadPeriod(startDate,endDate);
      if(!response) {
        alert("Algo salió mal al establecer el periodo, intente de nuevo");
      }
      else {
        setShowPeriodModal(false)
      }
    }else {
      alert("Su usuario no tiene los permisos para realizar esta operación")
    }
  }

  return (
    <Modal
    show={show}
    onHide={() => setShowPeriodModal(false)}
    centered
    className="custom-modal"
  >
    <Modal.Header closeButton>
      <Modal.Title>Establecer Periodo</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form className="text-center">
        <Form.Group controlId="startDate">
          <Form.Label>Fecha de inicio:</Form.Label>
          <div className="input-group justify-content-center">
            <span className="input-group-text">
              <FaCalendarAlt />
            </span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              maxDate={today}
              isClearable
              placeholderText="Seleccionar fecha de inicio"
              className="form-control date-picker-input"
            />
          </div>
        </Form.Group>

        <Form.Group controlId="endDate" className="mt-3">
          <Form.Label>Fecha de conclusión:</Form.Label>
          <div className="input-group justify-content-center">
            <span className="input-group-text">
              <FaCalendarAlt />
            </span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              maxDate={today}
              isClearable
              placeholderText="Seleccionar fecha de conclusión"
              className="form-control date-picker-input"
            />
          </div>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={() => setShowPeriodModal(false)}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={handleSaveValidation}>
        Descargar periodo
      </Button>
    </Modal.Footer>
  </Modal>
);
};

export default SetPeriodModal;