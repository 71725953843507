export const createUser = async (username, password) => {
    try {
        const response = await fetch("https://sosseguros.vulcanics.mx/api/create-user", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username" : username,
                "password" : password
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.log("Ocurrió un problema intentando crear el usuario: "+ JSON.stringify(response.status))
        }
    } catch (error) {
        console.log("Problemas al llamar la api para crear usuario", error)
    }
};