export const generateReport = async (startDate, endDate) => {
    try {
        const response = await fetch ("https://sosseguros.vulcanics.mx/api/generate-report", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "startDate" : startDate,
                "endDate" : endDate,
            })
        });
        if (response.ok) {
            console.log("Reporte generado exitosamente:\n");
            return response;
        } else {
            console.log("Ocurrió un problema intentando establecer el periodo: ")
            return false
        }
    } catch (error) {
        console.log("Problemas al llamar la api para establecerel rango de fechas ", error)
        return false;

    }
    
}