export const toggleConversation = async (userPhone) => {
    try{
        const response = await fetch('https://sosseguros.vulcanics.mx/api/toggle-in-use', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userPhone": userPhone
            })
        });

        if (response.ok) {
            const data = await response.json();  
            console.log("Se ha intercalado la conversdación", data);  
            return true;
        }
        else {
            const errorData = await response.json();  
            console.log("No se pudo mandar el mensaje", errorData); 
            return false;
        }
    } catch (error) {
        console.log("Error al momento de comunicarse con el servidor: " + error);
        return false;  
    }
}