export const getClients = async () => {
    try {
        const response = await fetch('https://sosseguros.vulcanics.mx/api/chats' ,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        
        if (response.ok) {
            const responseJson = response.json();
            const data = await responseJson;
            return data;
        }
    }catch (error) {
        console.log("Algo salió mal al consultar las conversaciones" + error)
    }
}