export const editUser = async (userId, username, password) => {
    try {
        const response = await fetch ("https://sosseguros.vulcanics.mx/api/update-user", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "userId" : userId,
                "username" : username,
                "password": password
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.log("Ocurrió un problema intentando editar el usuario: "+ JSON.stringify(response.status))
        }
    } catch (error) {
        console.log("Problemas al llamar la api para editar usuario", error)

    }
}