export const asignConversation = async (usuario_N, responsable) => {
    try {
        const response = await fetch('https://sosseguros.vulcanics.mx/api/asign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "usuario_N" : usuario_N,
                "responsable" : responsable
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.log("Ocurrió un problema: "+ JSON.stringify(response.status))
        }
    } catch (error) {
        console.log("No se pudo llamar al endpoint" + error);
    }
};