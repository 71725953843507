import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { editUser } from '../../api/editUser';

const EditUserModal = ({ show, closeEditUserModal, selectedRepresentative, setShouldFetch }) => {
  const [username, setUsername] = useState(selectedRepresentative?.username || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Efecto para resetear los valores al abrir el modal
  useEffect(() => {
    if (selectedRepresentative) {
      setUsername(selectedRepresentative.username);
    }
  }, [selectedRepresentative]);

  const handleSubmit = () => {
    if (!username || !password) {
      setError('El nombre de usuario y la contraseña son obligatorios.');
      return;
    }

    editUser(selectedRepresentative.id, username, password);
    setShouldFetch(true);
    handleClose();
  };

  const handleClose = () => {
    setError('');
    setPassword('');
    setUsername(selectedRepresentative?.username || ''); 
    closeEditUserModal();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar ejecutivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Nombre de usuario (actual)</Form.Label>
            <Form.Control
              type="text"
              readOnly
              placeholder={selectedRepresentative?.username || 'Cargando...'}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='mt-2'>Nuevo nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Ingrese el nuevo nombre de usuario"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='mt-2'>Nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese la nueva contraseña"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
